import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const PublicRoute = () => {
  const { isLoggedIn } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/admin/availability')
    }
  }, [isLoggedIn])
  return <Outlet />
}

export default PublicRoute
