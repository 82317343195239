export const LOGIN_FORM_FIELDS = [
  {
    key: "email",
    label: "Email",
    type: "email",
  },
  {
    key: "password",
    label: "Password",
    type: "password",
  },
];

export const REGISTER_FORM_FIELDS = [
  {
    key: "email",
    label: "Email",
    type: "email",
  },
  {
    key: "password",
    label: "Password",
    type: "password",
  },
  {
    key: "confirmPassword",
    label: "Confirm Password",
    type: "password",
  },
  {
    key: "registrationOTP",
    label: "O.T.P",
    type: "text",
  },
];

export const VERIFY_EMAIL_FORM_FIELDS = [
  {
    key: "email",
    label: "Email",
    type: "email",
  },
];

export const FORGOT_PASSWORD_FORM_FIELDS = [
  {
    key: "email",
    label: "Email",
    type: "email",
  },
];

export const RESET_PASSWORD_FIELDS = [
  {
    key: "email",
    label: "Email",
    type: "text",
  },
  {
    key: "temporaryKey",
    label: "O.T.P",
    type: "text",
  },
  {
    key: "newPassword",
    label: "New Password",
    type: "password",
  },
  {
    key: "confirmPassword",
    label: "Confirm New Password",
    type: "password",
  },
];

export const QUESTIONARIES_FIELDS = [
  {
    key: "firstName",
    label: "First Name",
    type: "text",
    md: 4,
    required: true,
    validations: {},
  },
  {
    key: "lastName",
    label: "Last Name",
    type: "text",
    md: 4,
    required: true,
    validations: {},
  },
  {
    key: "phone",
    label: "Phone Number",
    type: "text",
    md: 4,
    required: true,
    validations: {},
  },
  {
    key: "email",
    label: "Email",
    type: "email",
    md: 4,
    required: true,
    validations: {},
  },
  {
    key: "goals",
    label: "Please list your top three goals for therapy",
    type: "textarea",
    md: 4,
    required: true,
    validations: {},
  },
  {
    key: "beenToTherapy",
    label: "Have you ever been to therapy?",
    type: "radio",
    options: ["Yes", "No"],
    md: 4,
    required: true,
    validations: {},
  },
  {
    key: "receivedMentalHealthDiagnosis",
    label: "Have you ever received a mental health diagnosis?",
    type: "radio",
    options: ["Yes", "No", "Maybe"],
    md: 6,
    required: true,
    validations: {},
  },
  {
    key: "hospitalizedForMentalHealth",
    label: "Have you ever been hospitalized for your mental health? ",
    type: "radio",
    options: ["Yes", "No"],
    md: 6,
    required: true,
    validations: {},
  },
  {
    key: "topTwoConcerns",
    label: "What are the top two concerns regarding your struggles right now?",
    type: "textarea",
    md: 6,
    required: true,
    validations: {},
  },
  {
    key: "questions",
    label:
      "Please address any questions or concerns you have regarding therapy so that I can address them during your consultation call.",
    type: "text",
    md: 6,
    required: true,
    validations: {},
  },
  {
    key: "servicesInterestIn",
    label: `Which services are you interested in receiving? (Check all that apply) <br /> *Please note that I do not see minors for individual therapy. I will only see minors for Neurofeedback.`,
    type: "checkbox",
    options: ["Individual Adult Therapy", "Neurofeedback"],
    md: 6,
    required: true,
    validations: {},
    selectMultiple: true,
    errorLabel: "Which services are you interested in receiving?",
  },
  {
    key: "hearedAbout",
    label: "How did you hear about me?",
    type: "radio",
    options: [
      "Google Search",
      "Psychology Today",
      "Drove past and saw sign",
      "I was referred by a friend/family member",
      "I was referred by a healthcare provider",
      "Other",
    ],
    md: 6,
    required: true,
    validations: {},
  },
];

export const APPOINTMENT_FIELDS = [
  {
    key: "slotPrice",
    label: "Price",
    type: "number",
    required: true,
  },
  {
    key: "date",
    label: "Date",
    type: "date",
    required: true,
  },
  {
    key: "start",
    label: "Start Time",
    type: "time",
    required: true,
  },
  {
    key: "end",
    label: "End Time",
    type: "time",
    required: true,
  },
  {
    key: "description",
    label: "Description",
    type: "textArea",
    required: false,
  },
];
