import moment from "moment";

export const saveUserToLocalStorage = (userResponse, token) => {
  let user = {
    email: userResponse?.email,
    role: userResponse?.role,
    name: `${userResponse?.firstName} ${userResponse?.lastName}`,
    token: token,
  };

  localStorage.setItem("user", JSON.stringify(user));
};

export const filterEventsRespectiveToDate = (events, date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const filteredEvents = events.filter((event) => {
    const eventDate = event.start;
    return (
      eventDate.getFullYear() === year &&
      eventDate.getMonth() === month &&
      eventDate.getDate() === day
    );
  });

  filteredEvents.sort((a, b) => a.start - b.start);

  return filteredEvents.length > 0 ? filteredEvents[0] : null;
};

export const filterAppointmentsByTitle = (query, appointments) => {
  if (!query.trim()) return appointments;

  return appointments.filter((appointment) =>
    appointment?.description
      ?.toLowerCase()
      .trim()
      .includes(query.toLowerCase().trim())
  );
};

export const formatDate = (date) => {
  return moment(date).format("MMMM Do YYYY");
};

export const formatTime = (date) => {
  return moment(date).format("h:mm a");
};

// Convert a date to the scrollToTime format (time only)
export function convertToScrollToTime(dateString) {
  // Parse the date string into a Date object
  let date = dateString;

  // Create a new Date object for scrollToTime
  let scrollToTime = new Date();

  // Set the hours and minutes of scrollToTime to match the parsed date
  scrollToTime.setHours(date.getHours());
  scrollToTime.setMinutes(date.getHours());

  // Reset seconds and milliseconds to ensure scrollToTime points to the exact beginning of the minute
  scrollToTime.setSeconds(0);
  scrollToTime.setMilliseconds(0);

  return scrollToTime;
}

export const createAppointments = (obj) => {
  const {
    start,
    end,
    date,
    untillDate,
    price,
    description,
    daysInWeek,
    status,
    slotPrice,
    ...remaining
  } = obj;
  const appointments = [];

  const { startHours, startMinutes, endHours, endMinutes } = getHoursAndMinutes(
    start,
    end
  );

  if (daysInWeek?.length > 0 && untillDate) {
    const allDays = ["0", "1", "2", "3", "4", "5", "6"];
    const activeDays = daysInWeek.includes("All")
      ? allDays
      : daysInWeek.map(String);

    const startDay = getDateObjectFromInputString(date);
    const endDay = untillDate
      ? getDateObjectFromInputString(untillDate)
      : startDay;

    let currentDay = new Date(startDay);
    while (currentDay <= endDay) {
      if (activeDays.includes(currentDay.getDay().toString())) {
        const startDate = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate(),
          startHours,
          startMinutes
        );
        const endDate = new Date(
          currentDay.getFullYear(),
          currentDay.getMonth(),
          currentDay.getDate(),
          endHours,
          endMinutes
        );
        appointments.push({
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          ...(price && { price }),
          ...(description && { description }),
          status: "Available",
          slotPrice: slotPrice,
        });
      }
      currentDay.setDate(currentDay.getDate() + 1);
    }
  } else {
    const selectedDate = getDateObjectFromInputString(date);

    const startDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      startHours,
      startMinutes
    );
    const endDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      endHours,
      endMinutes
    );
    appointments.push({
      ...remaining,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      status: status ?? "Available",
      ...(price && { price }),
      ...(description && { description }),
      slotPrice,
    });
  }

  return appointments;
};

export const setCacheUserDetail = (formValues) => {
  localStorage.setItem("user-register-details", JSON.stringify(formValues));
};

export const getCacheUserDetail = () => {
  return JSON.parse(localStorage.getItem("user-register-details"));
};

export const getHoursAndMinutes = (start, end) => {
  // Determine if start and end are in hh:mm format or date format
  let startHours, startMinutes, endHours, endMinutes;

  if (
    typeof start === "string" &&
    start.includes(":") &&
    !start.includes("T")
  ) {
    [startHours, startMinutes] = start.split(":").map(Number);
  } else {
    const startDate = new Date(start);
    startHours = startDate.getHours();
    startMinutes = startDate.getMinutes();
  }

  if (typeof end === "string" && end.includes(":") && !end.includes("T")) {
    [endHours, endMinutes] = end.split(":").map(Number);
  } else {
    const endDate = new Date(end);
    endHours = endDate.getHours();
    endMinutes = endDate.getMinutes();
  }
  return { startHours, startMinutes, endHours, endMinutes };
};

// Convert ISO date string to HTML date input format (YYYY-MM-DD)
export function toHTMLDateInputFormat(isoDateString) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

// Convert ISO date string to HTML time input format (HH:MM)
export function toHTMLTimeInputFormat(isoDateString) {
  const date = new Date(isoDateString);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
}

export const getDateObjectFromInputString = (dateString) => {
  try {
    return new Date(dateString.split("-").join("/"));
  } catch (e) {
    return null;
  }
};
