import React from "react";
import { Card, Dropdown, Form } from "react-bootstrap";
import CustomEvent from "../../common/AppointmentCalendar/CustomEvent";
import { APPOINTMENT_TABS } from "../../helpers/constants";

const Sidebar = ({
  appointments = [],
  onActiveAppointmentValueChange,
  activeAppointment,
  activeFilter,
  onActiveFilterChange,
  quickSearchValue,
  onQuickSearchValueChange,
}) => {
  return (
    <div
      className="h-100"
      style={{
        width: "280px",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <Form.Control
          type="text"
          placeholder="Quick Search..."
          style={{ fontSize: "12px", marginRight: "10px" }}
          value={quickSearchValue}
          onChange={(e) => onQuickSearchValueChange(e.target.value)}
        />
        <Dropdown drop="down-centered">
          <Dropdown.Toggle
            variant="dark"
            className="pill"
            size="sm"
          ></Dropdown.Toggle>

          <Dropdown.Menu>
            {APPOINTMENT_TABS.map(({ label, key }) => {
              return (
                <Dropdown.Item
                  active={activeFilter === key}
                  onClick={() => onActiveFilterChange(key)}
                >
                  {label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Card className="mt-2 h-100 overflow-auto bg-light p-1">
        {/* searchbar and filter */}

        {!appointments.length && (
          <h6 className="tiny-text my-2 text-center flex-column justify-content-center h-100">
            No Appointments To Show
          </h6>
        )}

        {appointments.map((event) => {
          return (
            <div className="mb-1 hover shadow-sm">
              <CustomEvent
                event={event}
                onClick={() => onActiveAppointmentValueChange(event)}
                filterCard={true}
              />
            </div>
          );
        })}
      </Card>
    </div>
  );
};

export default Sidebar;
