import React, { createContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { saveUserToLocalStorage } from '../helpers/global'
import { authService } from '../services/authService'

export const AuthContext = createContext()

const getInitialUser = () => {
  if (localStorage.getItem('user')) {
    return JSON.parse(localStorage.getItem('user'))
  }
  return null
}

export const AuthProvider = ({ children }) => {
  const [gettingMe, setGettingMe] = useState(false)
  const [user, setUser] = useState(getInitialUser())
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(user))
  const [isRegistered, setIsRegistered] = useState(false) // This determines wheather is registed or not in order to transfer user to signIn
  const [loggingIn, setLoggingIn] = useState(false) // This state handles signIn, register, generateRegistrationOTP

  const [registrationOtpGenerated, setRegistrationOtpGenerated] = useState(false) // This determines if the registration otp is generated and helps to redirect user to complete registration
  const [resettingPassword, setResettingPassword] = useState(false)
  const [passwordResetSuccessfull, setPasswordResetSuccessfull] = useState(false)
  const [error, setError] = useState(null)
  const [forgotPasswordInProgress, setForgotPasswordInProgress] = useState(false)
  const [forgotPasswordSuccessfull, setForgotPasswordSuccessfull] = useState(false)

  useEffect(() => {
    setIsLoggedIn(Boolean(user))
  }, [user])

  const getMe = async () => {
    setError(null)
    setGettingMe(true)
    const { response, error } = await authService.getMe()
    if (error) {
      setGettingMe(false)
      toast.error(error)
      setError(error)
      return
    }
    if (response) {
      setUser(response)
    }
    setGettingMe(false)
  }

  const signIn = async (email, password) => {
    setLoggingIn(true)
    setError(null)

    const { response, error } = await authService.signIn(email, password)
    if (error) {
      setLoggingIn(false)
      toast.error(error)
      setError(error)
      return
    }
    if (response?.jwt && response?.user) {
      saveUserToLocalStorage(response?.user, response?.jwt)
      setUser(response?.user)
    }
    setLoggingIn(false)
  }

  const signOut = () => {
    setUser(null)
    localStorage.clear()
  }

  const register = async (formData) => {
    setLoggingIn(true)
    setError(null)

    const { response, error } = await authService.register(formData)
    if (error) {
      setLoggingIn(false)
      toast.error(error)
      setError(error)
      return
    }

    if (response?.jwt && response?.user) {
      saveUserToLocalStorage(response?.user, response?.jwt)
      setUser(response?.user)
      setIsRegistered(true)
    }

    setLoggingIn(false)
  }

  const generateRegistrationOTP = async (email) => {
    setLoggingIn(true)
    setError(null)
    const { response, error } = await authService.generateRegistrationOTP(email)

    if (error) {
      setLoggingIn(false)
      toast.error(error)
      setError(error)
      return
    }

    if (response) {
      setRegistrationOtpGenerated(true)
      toast.success('OTP is sent successfully to your email.')
    }
    setLoggingIn(false)
  }

  const resetPassword = async (formData) => {
    setError(null)
    setResettingPassword(true)
    const { response, error } = await authService.resetPassword({
      email: formData?.email,
      temporaryKey: formData?.temporaryKey,
      newPassword: formData?.newPassword
    })
    if (error) {
      setResettingPassword(false)
      setError(error)
      return toast.error(error)
    }

    setPasswordResetSuccessfull(true)
    setForgotPasswordSuccessfull(false)
    setResettingPassword(false)
    toast.success('Password Reset Successfull.')
  }

  const forgotPassword = async (email) => {
    setError(null)
    setForgotPasswordInProgress(true)
    const { response, error } = await authService.forgotPassword(email)

    if (error) {
      setError(error)
      setForgotPasswordInProgress(false)
      return toast.error(error)
    }

    toast.success('An OTP has been sent to your email address')
    setForgotPasswordSuccessfull(true)
    setForgotPasswordInProgress(false)
  }

  useEffect(() => {
    if (isLoggedIn) getMe()
  }, [])

  const onUserChange = (userObj) => {
    setUser(userObj)
  }

  const updateRegistrationOTPvalue = (value) => {
    setRegistrationOtpGenerated(value)
  }
  const updateForgotPasswordOTPvalue = (value) => {
    setForgotPasswordSuccessfull(value)
  }

  const contextObj = {
    onUserChange,
    resetPassword,
    register,
    signIn,
    signOut,
    generateRegistrationOTP,
    forgotPassword,
    updateRegistrationOTPvalue,
    updateForgotPasswordOTPvalue,
    error,
    user,
    loggingIn,
    isLoggedIn,
    isRegistered,
    registrationOtpGenerated,
    passwordResetSuccessfull,
    gettingMe,
    resettingPassword,
    forgotPasswordInProgress,
    forgotPasswordSuccessfull
  }

  return <AuthContext.Provider value={contextObj}>{children}</AuthContext.Provider>
}
