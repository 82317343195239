import moment from 'moment'
import React, { useEffect, useState, useMemo } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { convertToScrollToTime, filterEventsRespectiveToDate } from '../../helpers/global'
import CustomEvent from './CustomEvent'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

const formats = {
  eventTimeRangeFormat: () => {
    return ''
  }
}

const localizer = momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(Calendar)

const smallScreenWidth = 576
const mediumScreenWidth = 860

function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1)
}

const getMessages = ({ activeView, screenWidth }) => ({
  next:
    activeView !== 'day'
      ? activeView === 'week'
        ? `Next ${screenWidth <= mediumScreenWidth ? '3 days' : 'Week'}`
        : `Next ${capitalizeFirstLetter(activeView)}`
      : 'Next',
  previous:
    activeView !== 'day'
      ? activeView === 'week'
        ? `Previous ${screenWidth <= mediumScreenWidth ? '3 days' : 'Week'}`
        : `Previous ${capitalizeFirstLetter(activeView)}`
      : 'Previous',
  today:
    activeView !== 'day'
      ? activeView === 'week'
        ? `This ${screenWidth <= mediumScreenWidth ? '3 days' : 'Week'}`
        : `This ${capitalizeFirstLetter(activeView)}`
      : 'Today',
  week: screenWidth <= mediumScreenWidth ? '3 days' : 'Week'
})

/**
 *
 * @param mode can be picker | scheduler
 * @returns
 */
const AppointmentCalendar = ({
  onSlotSelect,
  appointments = [],
  mode = 'picker',
  allowEditing,
  onPlanDateTimeChange,
  onPlanLearnedChange,
  onCustomPlanEditClick,
  onCustomPlanDeleteClick,
  customEventComponent,
  customActiveView,
  customStep,
  onSessionDragAndDrop,
  calendarDate,
  setAddMoreCurrentDate,
  onCurrentlyActiveViewOnCalender,
  onAppointmentClick,
  timeAndDateToScroll,
  initialSmallScreenView,
  initialLargeScreenView
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [selectable, setSelectable] = useState(false)
  const [resizable, setResizable] = useState(false)
  const [activeView, setActiveView] = useState(
    screenWidth >= smallScreenWidth
      ? initialLargeScreenView || 'week'
      : initialSmallScreenView || 'day'
  )
  const [currentDate, setCurrentDate] = useState(new Date())
  const [scrollToTime, setScrollToTime] = useState(new Date())

  const handleSeeMore = (date) => {
    let now = new Date(date)
    setScrollToTime(convertToScrollToTime(now))
    setActiveView('week')
  }

  const handleEventClick = (event) => {
    onAppointmentClick && onAppointmentClick(event)
  }

  const handleNavigate = (newDate) => {
    setCurrentDate(newDate)
  }

  useEffect(() => {
    if (timeAndDateToScroll) {
      setActiveView('week')
      setCurrentDate(timeAndDateToScroll)
      setScrollToTime(convertToScrollToTime(timeAndDateToScroll))
    }
  }, [timeAndDateToScroll])

  useEffect(() => {
    // if (activeView === 'month') {
    //   setSelectable(false)
    //   setResizable(false)
    //   return
    // }

    setSelectable(allowEditing)
    setResizable(allowEditing)
  }, [mode, allowEditing, activeView])

  const slotPropGetter = (date) => ({
    style: {
      fontSize: 11,
      height: 80,
      zIndex: mode === 'picker' ? 1 : undefined
    },
    className: `${selectable ? 'hover hover-light' : ''} ${
      slotHasMultipleAppointments(date, appointments) ? 'red-border-right' : ''
    }`
  })

  const eventPropGetter = (event) => ({
    style: {
      fontSize: 11,
      zIndex: '9',
      backgroundColor: event.resource && event.resource.color ? event.resource.color : '#D7D7D9'
    }
  })

  const dayPropGetter = () => ({
    style: {
      fontSize: 11
    }
  })

  const onSlotSelectInternal = (slot) => {
    if (slotHasOverlappingAppointments(slot.start, slot.end, appointments)) return
    onSlotSelect && onSlotSelect(slot)
  }

  const onEventDropInternal = (dropEvent) => {
    //end accessor reduces the event by 1 milli, so adding back
    dropEvent.end.setTime(dropEvent.end.getTime() + 1)

    if (
      slotHasOverlappingAppointments(dropEvent.start, dropEvent.end, appointments, dropEvent.event)
    )
      return
    onSessionDragAndDrop && onSessionDragAndDrop(dropEvent)
    onPlanDateTimeChange && onPlanDateTimeChange(dropEvent)
  }

  const onEventResizeInternal = (resizeEvent) => {
    //cannot be less than 15
    if (resizeEvent.end.getTime() - resizeEvent.start.getTime() < 15 * 60000) {
      return
    }
    onSessionDragAndDrop && onSessionDragAndDrop(resizeEvent)
    if (
      slotHasOverlappingAppointments(
        resizeEvent.start,
        resizeEvent.end,
        appointments,
        resizeEvent.event
      )
    )
      return
    onPlanDateTimeChange && onPlanDateTimeChange(resizeEvent)
  }

  const onViewInternal = (view) => {
    setActiveView(view)
    customActiveView && onCurrentlyActiveViewOnCalender(view)
  }

  const messages = useMemo(
    () => getMessages({ activeView, screenWidth }),
    [activeView, screenWidth]
  )

  return (
    <>
      {resizable ? (
        <DragAndDropCalendar
          startAccessor="start"
          endAccessor="end"
          localizer={localizer}
          style={styles.calendar}
          messages={messages}
          events={appointments}
          step={customStep ?? 15}
          timeslots={1}
          scrollToTime={scrollToTime}
          view={activeView}
          elementProps={{
            style: {
              fontSize: 11
            }
          }}
          onView={onViewInternal}
          views={['month', 'week', 'day']}
          selectable={selectable}
          resizable={resizable}
          date={currentDate}
          onSelectSlot={onSlotSelectInternal}
          dayPropGetter={dayPropGetter}
          slotPropGetter={slotPropGetter}
          eventPropGetter={eventPropGetter}
          onEventDrop={onEventDropInternal}
          formats={formats}
          onNavigate={handleNavigate}
          components={{
            event: (props) => (
              <CustomEvent
                {...props}
                onClick={handleEventClick}
                timeAndDateToScroll={timeAndDateToScroll}
                view={activeView}
              />
            )
          }}
          onEventResize={onEventResizeInternal}
        />
      ) : (
        <Calendar
          startAccessor="start"
          endAccessor="end"
          localizer={localizer}
          style={styles.calendar}
          events={appointments}
          step={customStep ?? 15}
          messages={messages}
          timeslots={1}
          scrollToTime={scrollToTime}
          view={activeView}
          onView={onViewInternal}
          components={{
            event: (props) => (
              <CustomEvent
                {...props}
                onClick={handleEventClick}
                timeAndDateToScroll={timeAndDateToScroll}
                view={activeView}
              />
            )
          }}
          views={['month', 'week', 'day']}
          date={currentDate}
          formats={formats}
          selectable={selectable}
          onSelectSlot={onSlotSelectInternal}
          dayPropGetter={dayPropGetter}
          slotPropGetter={slotPropGetter}
          eventPropGetter={eventPropGetter}
          onNavigate={handleNavigate}
          onShowMore={(event, date) => {
            handleSeeMore(filterEventsRespectiveToDate(appointments, date)?.start)
          }}
        />
      )}
    </>
  )
}

const slotFallsOutsideAllowedHours = (start, end, dayStartHour, dayEndHour) => {
  return (
    start.getHours() * 60 + start.getMinutes() < dayStartHour * 60 ||
    end.getHours() * 60 + end.getMinutes() < dayStartHour * 60 ||
    start.getHours() * 60 + start.getMinutes() > dayEndHour * 60 ||
    end.getHours() * 60 + end.getMinutes() > dayEndHour * 60
  )
}

/**
 *
 * @param {Date} start
 * @param {Date} end
 * @param {[]} appointments
 * @returns
 */
const slotHasOverlappingAppointments = (start, end, appointments, currentPlan) => {
  let comparingappointments = currentPlan
    ? appointments.filter((p) => p !== currentPlan)
    : appointments
  return comparingappointments.some(
    (p) => end.getTime() > p.start.getTime() && p.end.getTime() > start.getTime()
  )
}

const slotHasMultipleAppointments = (slotDate, appointments) => {
  const slotStart = slotDate
  const slotEnd = new Date(slotDate)
  slotEnd.setMinutes(slotEnd.getMinutes() + 14, 59, 999)

  return (
    appointments.filter(
      (p) => p.start.getTime() >= slotStart.getTime() && p.start.getTime() <= slotEnd.getTime()
    ).length > 1
  )
}

const styles = {
  calendar: {
    height: '100%'
  }
}

export default AppointmentCalendar
