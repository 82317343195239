import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Auth from '../components/auth'
import PublicRouteLayout from '../layouts/PublicRouteLayout'
import PublicRoute from './PublicRoute'
import UserView from '../components/UserView'
import ProtectedRoute from './ProtectedRoute'
import ProtectedRouteLayout from '../layouts/ProtectedRouteLayout'
import Appointments from '../components/admin/Appointments'
import NotFound from '../common/NotFound'

const Index = () => {
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route element={<PublicRouteLayout pageTitle="Book An Appointment" />}>
          <Route path="/" element={<UserView />} />
        </Route>
        <Route element={<PublicRouteLayout pageTitle="Login" />}>
          <Route element={<Auth />} path="/login" />
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<ProtectedRouteLayout />}>
          <Route element={<Appointments />} path="/admin/availability" />
        </Route>
      </Route>
      {/* Catch-all route for handling page not found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Index
