import { useContext } from 'react'
import AppChoicesContext from '../context/AppChoiceContext'

const useAppChoices = (key) => {
  const appChoices = useContext(AppChoicesContext)

  const choicesToReturn = key ? appChoices?.find((choice) => choice.key === key) : appChoices

  return choicesToReturn
}

export default useAppChoices
