import React from "react";
import CachedResponse from "../common/CachedResponse";
import AppChoicesContext from "../context/AppChoiceContext";

const AppChoicesProvider = ({ children }) => {
  return (
    <CachedResponse
      listEndpoint={"app-choices"}
      render={(appChoices) => (
        <AppChoicesContext.Provider value={appChoices}>
          {children}
        </AppChoicesContext.Provider>
      )}
    />
  );
};

export default AppChoicesProvider;
