import React, { useState } from "react";
import { APPOINTMENT_CALENDER, QUESTIONARIES } from "../../helpers/constants";
import AppointmentCalender from "./appointmentCalender";
import Questionaries from "./questionaries";

const Index = () => {
  const [activeView, setActiveView] = useState(QUESTIONARIES);
  const [formValues, setFormValues] = useState({});

  const onFormValueChange = (formKey, value) => {
    formValues[formKey] = value;
    setFormValues({ ...formValues });
  };

  function onActiveViewChange(viewToBeActivated) {
    setActiveView(viewToBeActivated);
  }

  return (
    <div style={{ height: "calc( 100% - 67px )" }}>
      {activeView === QUESTIONARIES && (
        <>
          <Questionaries
            onActiveViewChange={onActiveViewChange}
            formValues={formValues}
            onFormValueChange={onFormValueChange}
            setFormValues={setFormValues}
          />
        </>
      )}

      {activeView === APPOINTMENT_CALENDER && (
        <AppointmentCalender
          formValues={formValues}
          activeView={activeView}
          onActiveViewChange={onActiveViewChange}
        />
      )}
    </div>
  );
};

export default Index;
