import React from "react";
import { Button } from "react-bootstrap";

const FloatingButton = ({
  text,
  variant = "primary",
  disabled = false,
  LeftIcon,
  RightIcon,
  onClick,
  className = "",
}) => {
  return (
    <Button
      variant={variant}
      style={{ position: "fixed", zIndex: 1000, bottom: 0, right: 0 }}
      className={`m-3 ${className}`}
      disabled={disabled}
      size="sm"
      onClick={onClick}
    >
      {LeftIcon && <LeftIcon className="mx-2" />}
      <span className="align-middle">{text}</span>
      {RightIcon && <RightIcon className="mx-2" />}
    </Button>
  );
};

export default FloatingButton;
