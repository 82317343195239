import { makeApiRequests } from '../helpers/apiHelper'
import { ENDPOINTS } from '../helpers/constants'

export const authService = {
  signIn: async (email, password) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.LOGIN,
      requestBody: { email, password }
    })

    return { response, error }
  },
  register: async (formData) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.REGISTER,
      requestBody: { ...formData }
    })

    return { response, error }
  },
  getMe: async () => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.GET_ME,
      requestBody: {}
    })

    return { response, error }
  },
  generateRegistrationOTP: async (email) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.GENERATE_REGISTRATION_OTP,
      requestBody: { email }
    })

    console.log(error)

    return { response, error }
  },
  forgotPassword: async (email) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.FORGOT_PASSWORD,
      requestBody: { email }
    })

    return { response, error }
  },
  resetPassword: async (formData) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.RESET_PASSWORD,
      requestBody: { ...formData }
    })

    return { response, error }
  }
}
