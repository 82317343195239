import React from "react";
import { Link, Outlet } from "react-router-dom";
import VerticalBar from "../common/VerticalBar";

const barWidth = 4;
const PublicRouteLayout = ({ pageTitle = "" }) => {
  return (
    <div className="vh-100">
      <div className="d-flex flex-column h-100 p-0">
        <div className="d-flex h-100">
          <div
            style={{ width: `${barWidth}%` }}
            className="d-block h-100 bg-primary"
          ></div>
          <div
            style={{ width: `${100 - barWidth}%` }}
            className="flex-grow-1 p-0 h-100 overflow-scroll"
          >
            <div className="w-100 d-flex align-items-center border-bottom p-2 sticky-top bg-white">
              <Link to={"/"}>
                <img
                  src="/SHC_Social-Profile_White-BG.jpg"
                  alt="companyLogo"
                  style={{
                    width: "40px",
                  }}
                  className="me-2"
                />
                <img
                  src="/SHC_Horizontal-Logo_Black_Transparent-BG.png"
                  alt="companyLogo"
                  style={{
                    width: "140px",
                  }}
                  className="mt-2"
                />
              </Link>

              {pageTitle && (
                <>
                  <VerticalBar height={20} className="mx-3 bg-muted" />
                  <h6 className="mb-0 xlarge-text fw-bold">{pageTitle}</h6>
                </>
              )}
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicRouteLayout;
