import { makeApiRequests } from "../helpers/apiHelper";
import { ENDPOINTS } from "../helpers/constants";

export const availabilityService = {
  search: async (search = {}) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.SEARCH_AVAILABILITY,
      requestBody: { ...search },
    });

    return { response, error };
  },
  update: async (availability) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.UPDATE_AVAILABILITY,
      requestBody: { timeSlotData: { ...availability } },
    });

    return { response, error };
  },
  delete: async (availability) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.DELETE_AVAILABILITY,
      requestBody: { ...availability },
    });

    return { response, error };
  },
  create: async (availabilities) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.CREATE_AVAILABILITY,
      requestBody: {
        timeSlots: availabilities,
      },
    });

    return { response, error };
  },
  book: async (timeSlotData) => {
    const { response, error } = await makeApiRequests({
      requestType: ENDPOINTS.BOOK_AVAILABILITY,
      requestBody: {
        timeSlotData,
      },
    });

    return { response, error };
  },
};
