import React from "react";
import { Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { FORGOT_PASSWORD_FORM_FIELDS } from "../../helpers/forms";
import useAuth from "../../hooks/useAuth";

const ForgotPassword = ({
  forgotPassModalMetadata,
  onHide,
  onForgotPassFieldValueChange,
  onFormSubmit,
}) => {
  const { forgotPasswordInProgress, updateForgotPasswordOTPvalue } = useAuth();

  return (
    <Modal
      show={forgotPassModalMetadata}
      onHide={onHide}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton={!forgotPasswordInProgress}>
        <Modal.Title>Forgot password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {FORGOT_PASSWORD_FORM_FIELDS.map(({ key, label, type }) => {
          return (
            <Form.Group className="mb-3" key={key} id={`fp-form-${key}`}>
              <Form.Label className="input-label">{label}</Form.Label>
              <Form.Control
                type={type}
                placeholder={`Enter ${label}`}
                value={forgotPassModalMetadata?.[key]}
                onChange={(e) => {
                  onForgotPassFieldValueChange(key, e.target.value);
                }}
                className="input-font"
              />
            </Form.Group>
          );
        })}
        <div className="w-100 d-flex justify-content-center">
          <Button
            variant="primary"
            onClick={onFormSubmit}
            disabled={forgotPasswordInProgress}
          >
            Submit
          </Button>
        </div>
        {forgotPasswordInProgress && (
          <ProgressBar
            variant="dark"
            animated
            now={100}
            label="Sending reset email..."
            className="mt-2"
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPassword;
