import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { CalendarDate, ChevronRight, InfoCircle } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { APPOINTMENT_CALENDER } from "../../../helpers/constants";
import { QUESTIONARIES_FIELDS } from "../../../helpers/forms";
import { onScrollToErrorPosition } from "../../../helpers/ui";
import FloatingButton from "../../../common/FloatingButton";
import {
  formatDate,
  getCacheUserDetail,
  setCacheUserDetail,
} from "../../../helpers/global";
import useAppChoices from "../../../hooks/useAppChoices";
import UnderlineButton from "../../../common/UnderlineButton";

function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

const organizeData = (data) => {
  const result = [];

  data.forEach((item) => {
    const splitKey = item.key.split("_");
    const title = splitKey[0].replace(/_/g, " ");
    const label = splitKey[1] ? splitKey[1].replace(/_/g, " ") : "";

    let group = result.find((groupItem) => groupItem.title === title);

    if (!group) {
      group = {
        title,
        subFields: [],
      };
      result.push(group);
    }

    group.subFields.push({
      label,
      description: item.value,
    });
  });

  return result;
};

const Index = ({
  onActiveViewChange,
  formValues,
  onFormValueChange,
  setFormValues,
}) => {
  const appChoice = useAppChoices().filter(
    (a) => a.key !== "SLOT_DEFAULT_PRICE"
  );

  const [error, setError] = useState({});
  const [showLoadCacheForm, setShowLoadCacheForm] = useState(true);

  const onButtonClick = () => {
    const requiredFields = QUESTIONARIES_FIELDS.filter(
      ({ required }) => required
    );

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    const emptyField = requiredFields.reduce((result, field) => {
      const key = field.key;
      // If the field is email, validate with regex
      if (key === "email" && !emailRegex.test(formValues[key])) {
        result[key] = true; // if not a valid email
      }
      // If field has an "Other" option, validate the text field instead
      else if (formValues[key] && formValues[key].checked === "Other") {
        result[key] = !formValues[key].text;
      } else {
        result[key] = formValues.hasOwnProperty(key) ? !formValues[key] : true;
      }
      return result;
    }, {});

    if (Object.keys(emptyField).some((k) => emptyField[k])) {
      setError(emptyField);
      const key = Object.keys(emptyField).find((k) => emptyField[k]);
      onScrollToErrorPosition(`fp-form-${key}`);
      return;
    }

    setCacheUserDetail({ ...formValues, date: new Date() });
    onActiveViewChange(APPOINTMENT_CALENDER);
  };

  const handleCacheValues = () => {
    const values = getCacheUserDetail();
    delete values.date;
    setFormValues({ ...values });
    setShowLoadCacheForm(false);
  };

  return (
    <div className="px-4 py-2 h-100 w-100 position-relative overflow-auto">
      <Row className="mt-1">
        {organizeData(appChoice).map((details) => (
          <Col md={details.colSize || 4} className="d-flex">
            <Card className="mb-3 p-0 flex-grow-1 shadow-lg">
              <Card.Header className="bg-dark px-2 py-2">
                <Card.Title className="fw-bold mb-0 text-white large-text">
                  {details.title}
                </Card.Title>
              </Card.Header>
              <Card.Body className="p-1">
                {details.subFields &&
                  details.subFields.map((subField) => (
                    <Card className="mb-1 shadow">
                      <Card.Body className="p-2">
                        {subField.label && (
                          <h6 className="mb-1 fw-bold large-text">
                            {toTitleCase(subField.label)}
                          </h6>
                        )}
                        <h6
                          className="mb-0 mid-text"
                          dangerouslySetInnerHTML={{
                            __html: subField.description,
                          }}
                        ></h6>
                      </Card.Body>
                    </Card>
                  ))}
                {details.description && (
                  <Card.Body
                    dangerouslySetInnerHTML={{ __html: details.description }}
                  ></Card.Body>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {showLoadCacheForm && getCacheUserDetail() && (
        <div
          className="w-full d-flex rounded p-2 align-items-center hover mb-2 text-white bg-dark"
          onClick={handleCacheValues}
        >
          <InfoCircle className="mx-2" /> Click Here To Use Previous Booking
          Details from {formatDate(getCacheUserDetail()["date"])}
        </div>
      )}
      <Card className="mb-3 p-0 flex-grow-1 shadow-lg">
        <Card.Header className="bg-dark">
          <Card.Title
            style={{ fontWeight: 700 }}
            className="mb-0 text-white xlarge-text"
          >
            Please fill in the details below:
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-3">
          <Row>
            {QUESTIONARIES_FIELDS.map(
              ({
                key,
                label,
                type,
                md,
                xs,
                required,
                options,
                selectMultiple,
                errorLabel,
              }) => {
                return (
                  <Col
                    md={md || 6}
                    xs={xs || 12}
                    key={key}
                    className="align-self-start"
                  >
                    <Form.Group
                      className="mb-4"
                      key={key}
                      id={`fp-form-${key}`}
                    >
                      <Form.Label className="input-label">
                        <span
                          style={{ fontWeight: 700 }}
                          dangerouslySetInnerHTML={{ __html: label }}
                        ></span>
                        {required && <span className="required-star">*</span>}
                      </Form.Label>
                      {options ? (
                        options.map((o) => (
                          <div className="d-flex">
                            <Form.Check
                              checked={
                                selectMultiple
                                  ? formValues[key] &&
                                    formValues[key].includes(o)
                                  : formValues[key] === o ||
                                    formValues[key]?.checked === o
                              }
                              type={type}
                              label={o}
                              className={`input-font ms-0 `}
                              onChange={(e) => {
                                setError({ ...error, [key]: false });
                                if (selectMultiple) {
                                  if (
                                    formValues[key] &&
                                    formValues[key].includes(o)
                                  ) {
                                    onFormValueChange(
                                      key,
                                      formValues[key].filter((k) => k !== o)
                                    );
                                  } else {
                                    onFormValueChange(
                                      key,
                                      formValues[key]
                                        ? [...formValues[key], o]
                                        : [o]
                                    );
                                  }
                                  return;
                                }
                                if (key === "hearedAbout") {
                                  if (o === "Other") {
                                    onFormValueChange(key, {
                                      checked: "Other",
                                      text: "",
                                    });
                                    return;
                                  }
                                }
                                onFormValueChange(key, o);
                              }}
                            />
                            {formValues[key]?.checked === o &&
                              o === "Other" && (
                                <Form.Control
                                  placeholder="Other Places..."
                                  value={formValues[key]?.text}
                                  type={"text"}
                                  style={{
                                    height: "20px",
                                  }}
                                  className={`input-font ${
                                    error[key] && "border-danger"
                                  } outline-none ms-1`}
                                  onChange={(e) => {
                                    setError({ ...error, [key]: false });
                                    onFormValueChange(key, {
                                      checked: "Other",
                                      text: e.target.value,
                                    });
                                  }}
                                />
                              )}
                          </div>
                        ))
                      ) : (
                        <>
                          {type === "textarea" ? (
                            <Form.Control
                              as="textarea"
                              placeholder={`Enter ${label}`}
                              className={`input-font ${
                                error[key] && "border-danger"
                              } ms-0`}
                              value={formValues[key]}
                              onChange={(e) => {
                                setError({ ...error, [key]: false });
                                onFormValueChange(key, e.target.value);
                              }}
                            />
                          ) : (
                            <Form.Control
                              value={formValues[key]}
                              type={type}
                              placeholder={`Enter ${label}`}
                              className={`input-font ${
                                error[key] && "border-danger"
                              } ms-0`}
                              onChange={(e) => {
                                setError({ ...error, [key]: false });
                                onFormValueChange(key, e.target.value);
                              }}
                            />
                          )}
                        </>
                      )}
                      {error[key] && (
                        <p className="tiny-text text-danger mt-1 mb-0">{`${
                          type === "email" ? "Valid " : ""
                        }${errorLabel ?? label} is required field`}</p>
                      )}
                    </Form.Group>
                  </Col>
                );
              }
            )}
          </Row>
        </Card.Body>
      </Card>
      <FloatingButton
        onClick={onButtonClick}
        LeftIcon={CalendarDate}
        RightIcon={ChevronRight}
        text={"Book A Date"}
      />
    </div>
  );
};

export default Index;
