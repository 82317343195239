import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./StripeCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { STRIPE_PK_KEY } from "../../helpers/constants";
import { availabilityService } from "../../services/availabilityService";

const stripePromise = loadStripe(STRIPE_PK_KEY);

export default function StripePayment({
  openStripePayment,
  onStripePaymentClose,
  onPaymentSuccess,
  onPaymentError,
  appointmentToBook,
  formValues,
}) {
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    setClientSecret(null);

    if (openStripePayment) {
      createContract();
    }
  }, [openStripePayment]);

  const createContract = async () => {
    try {
      const { response, error } = await availabilityService.book({
        ...appointmentToBook,
        form: formValues,
      });

      if (error) {
        toast.error(error);
        setClientSecret(null);
        onPaymentError && onPaymentError();
        return;
      }

      setClientSecret(response.paymentDetails);
    } catch (e) {
      toast.error("Something went wrong!");
      onPaymentError && onPaymentError();
      setClientSecret(null);
    }
  };

  const appearance = {
    theme: "stripe",
  };

  return (
    <div>
      <Modal
        show={openStripePayment}
        onHide={() => {
          setClientSecret(false);
          onStripePaymentClose();
        }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Book Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {stripePromise && clientSecret ? (
            <Elements stripe={stripePromise} options={{ appearance }}>
              <CheckoutForm
                clientSecret={clientSecret}
                onStripePaymentClose={onStripePaymentClose}
                onPaymentSuccess={onPaymentSuccess}
              />
            </Elements>
          ) : (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
