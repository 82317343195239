import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useAuth = () => {
  const {
    user,
    onUserChange,
    resetPassword,
    loggingIn,
    isLoggedIn,
    register,
    signIn,
    signOut,
    updateRegistrationOTPvalue,
    generateRegistrationOTP,
    isRegistered,
    generatingRegsitrationOtp,
    updateForgotPasswordOTPvalue,
    passwordReset,
    gettingMe,
    resettingPassword,
    error,
    forgotPasswordInProgress,
    forgotPasswordSuccessfull,
    forgotPassword,
    passwordResetSuccessfull,
    registrationOtpGenerated,
  } = useContext(AuthContext);

  return {
    onUserChange,
    resetPassword,
    register,
    signIn,
    signOut,
    generateRegistrationOTP,
    forgotPassword,
    updateRegistrationOTPvalue,
    user,
    loggingIn,
    isLoggedIn,
    isRegistered,
    generatingRegsitrationOtp,
    registrationOtpGenerated,
    passwordReset,
    gettingMe,
    resettingPassword,
    error,
    forgotPasswordInProgress,
    forgotPasswordSuccessfull,
    passwordResetSuccessfull,
    updateForgotPasswordOTPvalue,
  };
};

export default useAuth;
