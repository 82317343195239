const NotFound = ({ text }) => {
  return (
    <div className='h-100 w-100'>
      <p className='d-flex align-items-center justify-content-center h-100'>
        {text || 'Page Not Found!'}
      </p>
    </div>
  )
}

export default NotFound
