import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import { PersonBadge } from "react-bootstrap-icons/dist";
import { LOGIN_MODE, REGISTER_MODE } from "../../helpers/constants";
import {
  LOGIN_FORM_FIELDS,
  REGISTER_FORM_FIELDS,
  RESET_PASSWORD_FIELDS,
  VERIFY_EMAIL_FORM_FIELDS,
} from "../../helpers/forms";
import useAuth from "../../hooks/useAuth";
import ForgotPassword from "./ForgotPassword";
import LoginOrRegister from "./LoginOrRegister";
import ResetPassword from "./ResetPassword";

const Auth = () => {
  const {
    resetPassword,
    register,
    signIn,
    forgotPassword,
    generateRegistrationOTP,
    loggingIn,
    registrationOtpGenerated,
    passwordResetSuccessfull,
    forgotPasswordSuccessfull,
    updateRegistrationOTPvalue,
  } = useAuth();

  const [mode, setMode] = useState(LOGIN_MODE); // Login or Register Mode
  const [formInfo, setFormInfo] = useState({}); // Register or Login Form Info
  const [forgotPassModalMetadata, setForgotPassModalMetadata] = useState(null); // Forgot Pass Modal formInfo
  const [resetPasswordFormMetadata, setResetPasswordFormMetadata] =
    useState(null); // Reset Pass Modal formInfo

  const onFormInfoChange = (key, value) => {
    formInfo[key] = value;
    setFormInfo({ ...formInfo });
  };

  const onModeChange = (value) => {
    setMode(value);
  };

  const onForgotPassModalChange = (value) => {
    setForgotPassModalMetadata(value);
  };

  const onForgotPassFieldValueChange = (key, value) => {
    forgotPassModalMetadata[key] = value;
    setForgotPassModalMetadata({ ...forgotPassModalMetadata });
  };

  const onResetPasswordFormMetadataChange = (key, value) => {
    resetPasswordFormMetadata[key] = value;
    setResetPasswordFormMetadata({ ...resetPasswordFormMetadata });
  };

  useEffect(() => {
    if (mode === REGISTER_MODE) {
      setFormInfo({});
    }

    updateRegistrationOTPvalue(false);
  }, [mode]);

  const auth = async () => {
    if (mode === REGISTER_MODE) {
      register({ ...formInfo, confirmPassword: undefined });
    } else {
      signIn(formInfo?.email, formInfo?.password);
    }
  };

  const onEmailVerification = async () => {
    if (!registrationOtpGenerated) {
      const emptyField = VERIFY_EMAIL_FORM_FIELDS.find(
        (field) => !formInfo[field?.key]
      );

      if (emptyField) {
        return toast.error(`${emptyField?.label} Is Empty`);
      }
      generateRegistrationOTP(formInfo?.email);
    }
  };

  const onLoginFormSubmit = async () => {
    const emptyField = LOGIN_FORM_FIELDS.find((field) => !formInfo[field?.key]);

    if (emptyField) {
      return toast.error(`${emptyField?.label} Is Empty`);
    }

    await auth();
  };

  const onRegisterFormSubmit = async () => {
    const password = formInfo?.password;
    const confirmPassword = formInfo?.confirmPassword;

    const emptyField = REGISTER_FORM_FIELDS.find(
      (field) => !formInfo[field?.key]
    );

    if (emptyField) {
      return toast.error(`${emptyField?.label} Is Empty`);
    }

    if (password !== confirmPassword) {
      return toast.error("Passwords Didnot Match");
    }

    await auth();
  };

  const onForgotPasswordFormSubmit = async () => {
    if (!forgotPassModalMetadata?.email) {
      return toast.error("Email Is Empty");
    }
    forgotPassword(forgotPassModalMetadata?.email);
  };

  const onResetPasswordFormSubmit = async () => {
    const newPassword = resetPasswordFormMetadata?.newPassword;
    const confirmPassword = resetPasswordFormMetadata?.confirmPassword;

    const emptyField = RESET_PASSWORD_FIELDS.find(
      (field) => !resetPasswordFormMetadata[field?.key]
    );

    if (emptyField) {
      return toast.error(`${emptyField?.label} Is Empty`);
    }

    if (newPassword !== confirmPassword) {
      return toast.error(`Password Didnot Match.`);
    }

    resetPassword({
      email: forgotPassModalMetadata?.email,
      ...resetPasswordFormMetadata,
    });

    if (passwordResetSuccessfull) {
      setResetPasswordFormMetadata(null);
      setForgotPassModalMetadata(null);
    }
  };

  useEffect(() => {
    if (forgotPasswordSuccessfull) {
      setResetPasswordFormMetadata({ email: forgotPassModalMetadata?.email });
    }
  }, [forgotPasswordSuccessfull]);

  useEffect(() => {
    if (passwordResetSuccessfull) {
      setResetPasswordFormMetadata(null);
      setForgotPassModalMetadata(null);
    }
  }, [passwordResetSuccessfull]);

  return (
    <>
      {resetPasswordFormMetadata ? (
        <ResetPassword
          resetPasswordFormMetadata={resetPasswordFormMetadata}
          onResetPasswordFormMetadataChange={onResetPasswordFormMetadataChange}
          onHide={() => setResetPasswordFormMetadata(null)}
          onFormSubmit={onResetPasswordFormSubmit}
          forgotPassModalMetadata={forgotPassModalMetadata}
          onForgotPassFieldValueChange={onForgotPassFieldValueChange}
        />
      ) : (
        <ForgotPassword
          forgotPassModalMetadata={forgotPassModalMetadata}
          onHide={() => onForgotPassModalChange(null)}
          onForgotPassFieldValueChange={onForgotPassFieldValueChange}
          onFormSubmit={onForgotPasswordFormSubmit}
        />
      )}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc( 100% - 49px )" }}
      >
        <div className="w-50 border rounded overflow-hidden">
          <div
            className="d-flex flex-column justify-content-center align-items-center bg-primary-light"
            style={{
              height: "120px",
              position: "relative", // Add this
            }}
          >
            <img
              src="/logo-transparent-bg.png"
              alt="logo"
              style={{
                height: "80%",
                objectFit: "contain",
                position: "absolute", // Add this
                zIndex: 2, // Add this
              }}
            />
          </div>

          <hr className="mt-0" />

          <div className="pb-2 px-3">
            <h5 className="xlarge-text text-center text-underline text-dark mb-2">
              <u>
                <PersonBadge size={15} className="me-1" />
                {mode === LOGIN_MODE
                  ? "Login"
                  : registrationOtpGenerated
                  ? "Register"
                  : "Verify Email"}
              </u>
            </h5>
            <LoginOrRegister
              formInfo={formInfo}
              onFormInfoChange={onFormInfoChange}
              fields={
                mode === LOGIN_MODE
                  ? LOGIN_FORM_FIELDS
                  : registrationOtpGenerated
                  ? REGISTER_FORM_FIELDS
                  : VERIFY_EMAIL_FORM_FIELDS
              }
              mode={mode}
              styles={styles}
              onModeChange={onModeChange}
              onForgotPassModalChange={onForgotPassModalChange}
              onFormSubmit={
                mode === LOGIN_MODE ? onLoginFormSubmit : onRegisterFormSubmit
              }
              onEmailVerification={onEmailVerification}
            />

            {loggingIn && (
              <ProgressBar
                className="mt-1"
                striped
                animated
                variant="dark"
                now={100}
                label={
                  mode === LOGIN_MODE
                    ? "Logging in..."
                    : registrationOtpGenerated
                    ? "Creating account..."
                    : "Sending OTP"
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  loginSignupText: {
    fontSize: 12,
    cursor: "pointer",
    textDecoration: "underline",
  },
};

export default Auth;
