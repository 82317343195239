export const makeApiRequests = async ({ requestType, requestBody = {} }) => {
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  try {
    const response = await (
      await fetch(process.env.REACT_APP_API_URL, {
        method: "POST",
        body: JSON.stringify({
          ...(token && { accessToken: token }),
          apiKey: process.env.REACT_APP_API_KEY,
          requestType,
          payload: requestBody,
        }),
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      })
    ).json();

    if (response["success"]) {
      return { response };
    } else {
      return { error: response["message"] || "Oops something went wrong!" };
    }
  } catch (e) {
    return { error: "Oops something went wrong!" };
  }
};
