import React, { useEffect, useState } from "react";
import AppointmentCalendar from "../../../common/AppointmentCalendar";
import CalendarSidebar from "../../../common/CalendarSidebar";
import StripePayment from "../../../common/StripePayment/stripe";
import { Spinner } from "react-bootstrap";
import NotFound from "../../../common/NotFound";
import { availabilityService } from "../../../services/availabilityService";
import { toast } from "react-toastify";
import { CheckCircleFill } from "react-bootstrap-icons";

const Index = ({ formValues }) => {
  const [activeEvent, setActiveEvent] = useState(null);
  const [bookAppointMent, setBookAppointment] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    getAvailabilities();
  }, []);

  const getAvailabilities = async () => {
    setIsLoading(true);
    try {
      const { response, error } = await availabilityService.search();
      if (error) {
        toast.error(error);
        return;
      }
      setAppointments(response?.timeSlots ?? []);
    } catch (e) {
      toast.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  if (paymentSuccess) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center p-4 border rounded">
          <CheckCircleFill size={25} className="text-success" />

          <h6 className="fw-bolder ms-2 mb-0 xlarge-text">
            Thank you for booking your appointment. Your payment has been
            received.
            <br /> Please check your email for appointment details.
          </h6>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {appointments?.length > 0 ? (
        <div className="px-4 py-2" style={{ height: "100%" }}>
          <AppointmentCalendar
            appointments={appointments.map((e) => {
              return {
                ...e,
                start: new Date(e.start),
                end: new Date(e.end),
              };
            })}
            onAppointmentClick={(event) => setActiveEvent(event)}
            initialSmallScreenView={"month"}
            initialLargeScreenView={"month"}
          />
          <StripePayment
            onStripePaymentClose={() => setBookAppointment(false)}
            openStripePayment={Boolean(bookAppointMent)}
            appointmentToBook={bookAppointMent}
            formValues={formValues}
            onPaymentSuccess={(success) => setPaymentSuccess(success)}
            onPaymentError={() => setBookAppointment(false)}
          />
          <CalendarSidebar
            onBook={(appointment) => setBookAppointment(appointment)}
            appointment={activeEvent}
            title={"Availability Detail"}
            onClose={() => setActiveEvent(null)}
          />
        </div>
      ) : (
        <NotFound text={" No Appointments to book!"} />
      )}
    </>
  );
};

export default Index;
