import { Clock, Calendar } from 'react-bootstrap-icons'
import { EVENT_STATUS } from '../../helpers/constants'
import { formatTime, formatDate } from '../../helpers/global'
const CustomEvent = ({ event, onClick, filterCard }) => {
  return (
    <div
      onClick={() => onClick && onClick(event)}
      style={{
        borderRadius: '5px'
      }}
      className={`bg-white border overflow-hidden w-100`}
    >
      <div className="d-flex rounded h-100 w-100">
        <span
          className={`bg-${EVENT_STATUS[event.status]}`}
          style={{
            width: '4px'
          }}
        ></span>
        <div className="p-1 w-100">
          <strong
            className={` ${
              filterCard ? 'small-text' : 'tinner-text'
            } text-black text-truncate d-block m-0 d-flex align-items-center`}
          >
            <Calendar className="me-1" />
            {formatDate(event.start)}
          </strong>
          {event?.description && (
            <p
              className={` ${
                filterCard ? 'small-text' : 'tinner-text'
              } text-black text-truncate d-block m-0`}
            >
              {event.description}
            </p>
          )}
          <p
            className={` ${
              filterCard ? 'small-text' : 'tinner-text'
            } text-black text-truncate d-block m-0 d-flex align-items-center`}
          >
            <Clock className="me-1" />
            {formatTime(event.start) + ' - ' + formatTime(event.end)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CustomEvent
