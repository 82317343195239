import React, { useState } from 'react'
import { Badge, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import CustomModal from '../../common/CustomModal'
import { APPOINTMENT_FIELDS } from '../../helpers/forms'
import { daysInWeek } from '../../helpers/constants'
import moment from 'moment'
import { toast } from 'react-toastify'
import { getHoursAndMinutes } from '../../helpers/global'

const AddEditAppointment = ({
  appointmentMetadata,
  onAppointmentModalChange,
  onAppointmentModalFieldsChange,
  onAppointmentAdd,
  showProgress
}) => {
  const [appointmentInfo, setAppointmentInfo] = useState()
  const [error, setError] = useState({
    title: false,
    date: false,
    start: false,
    end: false,
    description: false
  })
  const handleSessionRepeat = (option) => {
    const allDays = [0, 1, 2, 3, 4, 5, 6]
    const previousOptions = appointmentInfo?.daysInWeek || []
    const newOptions =
      option === 'All'
        ? previousOptions.includes('All')
          ? []
          : [...allDays, 'All']
        : previousOptions.includes(option)
        ? previousOptions.filter((x) => x !== option && x !== 'All')
        : [...previousOptions, option]
    if (newOptions.length === 7) newOptions.push('All')
    setAppointmentInfo({ ...appointmentInfo, daysInWeek: newOptions })
  }
  const getEditingValue = (field) => {
    const value = appointmentMetadata[field?.key]

    if (field.type === 'time' && value) {
      if (/^\d{2}:\d{2}$/.test(value)) {
        return value
      }
      return moment(value).format('hh:mm')
    } else if (field.type === 'date' && value) {
      return moment(value).format('YYYY-MM-DD') // Corrected the year format
    } else {
      return value
    }
  }
  const renderFormInput = (field) => {
    if (field.type === 'textArea') {
      return (
        <Form.Group controlId={field.key}>
          <Form.Label>{field.label}</Form.Label>
          {field?.required && <span className="required-star">*</span>}
          <Form.Control
            as="textarea"
            className={`input-font ${error[field.key] && 'border-danger'} ms-0`}
            required={field.required}
            name={field.key}
            value={appointmentMetadata[field?.key]}
            onChange={(e) => {
              setError({ ...error, [field.key]: false })
              onAppointmentModalFieldsChange(field?.key, e.target.value)
            }}
          />
          {error[field.key] && (
            <p className="tiny-text text-danger mt-1">{`${field.label} is required field`}</p>
          )}
        </Form.Group>
      )
    }
    return (
      <Form.Group controlId={field.key}>
        <Form.Label>
          {field.label} {field?.required && <span className="required-star">*</span>}
        </Form.Label>
        <Form.Control
          type={field.type}
          className={`input-font ${error[field.key] && 'border-danger'} ms-0`}
          required={field.required}
          name={field.key}
          value={getEditingValue(field)}
          onChange={(e) => {
            setError({ ...error, [field.key]: false })
            onAppointmentModalFieldsChange(field?.key, e.target.value)
          }}
        />
        {error[field.key] && (
          <p className="tiny-text text-danger mt-1">{`${field.label} is required field`}</p>
        )}
      </Form.Group>
    )
  }
  const handleOnAddAppointMent = () => {
    const emptyField = APPOINTMENT_FIELDS.reduce((result, field) => {
      const key = field.key
      if (field.required) {
        result[key] = appointmentMetadata.hasOwnProperty(key) ? !appointmentMetadata[key] : true
      }
      return result
    }, {})

    if (Object.keys(emptyField).some((k) => emptyField[k])) {
      setError(emptyField)
      return
    }
    if (
      appointmentInfo?.untillDate &&
      new Date(appointmentMetadata?.date) > new Date(appointmentInfo?.untillDate)
    ) {
      toast.error('Repeat untill date should be less then start date')
      return
    }
    const { startHours, startMinutes, endHours, endMinutes } = getHoursAndMinutes(
      appointmentMetadata?.start,
      appointmentMetadata?.end
    )
    const todayDate = new Date(appointmentMetadata?.date)

    const startDate = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate(),
      startHours,
      startMinutes
    )
    const endDate = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate(),
      endHours,
      endMinutes
    )
    if (startDate > endDate) {
      toast.error('Start time should not be more than end time')
      return
    }
    onAppointmentAdd({ ...appointmentMetadata, ...appointmentInfo })
  }
  return (
    <>
      <CustomModal
        show={appointmentMetadata !== null}
        onHide={() => onAppointmentModalChange(null)}
        heading={`${appointmentMetadata?.editing ? 'Update' : 'Add'} Availability`}
        showProgress={showProgress}
        onSubmit={() => handleOnAddAppointMent()}
      >
        <Form>
          <Container>
            <Row>
              {APPOINTMENT_FIELDS.map((field) => (
                <Col md={6}>{renderFormInput(field)}</Col>
              ))}
              {!appointmentInfo?._id && !appointmentMetadata?.editing && (
                <>
                  <Col xs={6} className="mt-3">
                    <h6 className="small mb-1 text-muted">Repeat Every:</h6>
                    <div className="d-flex mt-1 ">
                      <Badge
                        className={`text-white hover mx-1 px-1 ${
                          appointmentInfo?.daysInWeek?.includes('All') ? 'bg-dark' : 'bg-muted'
                        }`}
                        onClick={() => handleSessionRepeat('All')}
                      >
                        <h6
                          className={`tiny-text mb-0 ${
                            appointmentInfo?.daysInWeek?.includes('All')
                              ? 'text-white'
                              : 'text-black'
                          }`}
                        >
                          All
                        </h6>
                      </Badge>
                      {daysInWeek.map((option, index) => {
                        return (
                          <Badge
                            className={`hover mx-1 px-1 ${
                              appointmentInfo?.daysInWeek?.includes(index) ? 'bg-dark' : 'bg-muted'
                            }`}
                            onClick={() => handleSessionRepeat(index)}
                          >
                            <h6
                              className={`tiny-text mb-0 ${
                                appointmentInfo?.daysInWeek?.includes(index)
                                  ? 'text-white'
                                  : 'text-black'
                              }`}
                            >
                              {option}
                            </h6>
                          </Badge>
                        )
                      })}
                    </div>
                  </Col>
                  {appointmentInfo?.daysInWeek && appointmentInfo?.daysInWeek?.length !== 0 && (
                    <>
                      <Col xs={6} className="mt-3">
                        <>
                          <h6 className="small mb-1 text-muted">Repeat until:</h6>
                          <div className="d-flex">
                            <input
                              placeholder=""
                              required=""
                              rows="2"
                              type="date"
                              className="form-control form-control-sm"
                              value={
                                appointmentInfo?.untillDate &&
                                moment(appointmentInfo?.untillDate).format('yyyy-MM-DD')
                              }
                              onChange={(e) =>
                                setAppointmentInfo({
                                  ...appointmentInfo,
                                  untillDate: e.target.value
                                })
                              }
                            ></input>
                          </div>
                        </>
                      </Col>
                    </>
                  )}
                </>
              )}
            </Row>
          </Container>
        </Form>
        {showProgress && (
          <div className="d-flex justify-content-center align-items-center mt-3">
            <Spinner />
          </div>
        )}
      </CustomModal>
    </>
  )
}

export default AddEditAppointment
