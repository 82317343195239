import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

function CustomModal({ show, onHide, heading = '', children, onSubmit, showProgress }) {
  return (
    <Modal show={show} size="lg" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="xlarge-text">
          {heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="danger" disabled={showProgress}>
          Close
        </Button>
        <Button onClick={onSubmit} variant="success" className="text-white" disabled={showProgress}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CustomModal
