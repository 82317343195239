import React from "react";
import { Button, Form } from "react-bootstrap";
import { LOGIN_MODE, REGISTER_MODE } from "../../helpers/constants";
import useAuth from "../../hooks/useAuth";

const LoginOrRegister = ({
  formInfo,
  onFormInfoChange,
  fields,
  mode,
  styles,
  onModeChange,
  onForgotPassModalChange,
  onFormSubmit,
  onEmailVerification,
}) => {
  const { loggingIn, registrationOtpGenerated } = useAuth();

  return (
    <>
      {fields.map(({ key, label, type }) => {
        return (
          <Form.Group className="mb-3" id={`${mode}-form-${key}`} key={key}>
            <Form.Label className="input-label">{label}</Form.Label>
            <Form.Control
              type={type}
              placeholder={`${label}`}
              value={formInfo?.[key] || ""}
              onChange={(e) => onFormInfoChange(key, e.target.value)}
              autocomplete="off"
              className="input-font"
            />
          </Form.Group>
        );
      })}

      <div className="w-100 d-flex justify-content-end">
        <Button
          variant="dark"
          size="sm"
          className="text-white"
          onClick={
            mode === LOGIN_MODE
              ? onFormSubmit
              : registrationOtpGenerated
              ? onFormSubmit
              : onEmailVerification
          }
          disabled={loggingIn}
        >
          {mode === LOGIN_MODE
            ? "Login"
            : registrationOtpGenerated
            ? "Register"
            : "Verify Email"}
        </Button>
      </div>

      {mode === LOGIN_MODE && (
        <>
          <p
            style={styles.loginSignupText}
            className={`text-right mx-2 ${loggingIn ? "text-muted" : ""}`}
            onClick={() => {
              if (loggingIn) return;
              onForgotPassModalChange({});
            }}
          >
            Forgot password?
          </p>
          <hr />
          <p
            style={styles.loginSignupText}
            className={`text-center mx-2 ${loggingIn ? "text-muted" : ""}`}
            onClick={() => {
              if (loggingIn) return;
              onModeChange(REGISTER_MODE);
            }}
          >
            Don't have an account? Click here to register
          </p>
        </>
      )}

      {mode === REGISTER_MODE && (
        <>
          <hr />
          <p
            style={styles.loginSignupText}
            className={`text-center mb-0 ${loggingIn ? "text-muted" : ""}`}
            onClick={() => {
              if (loggingIn) return;
              onModeChange(LOGIN_MODE);
            }}
          >
            Already have an account? Click here to login.
          </p>
        </>
      )}
    </>
  );
};

export default LoginOrRegister;
