import { isArray } from "lodash";
import React from "react";
import { Button } from "react-bootstrap";
import {
  Calendar,
  CheckCircle,
  Clock,
  CurrencyDollar,
  FileEarmark,
  PencilSquare,
  Trash,
} from "react-bootstrap-icons";
import { USER_DETAILS_KEY_VALUE } from "../helpers/constants";
import { formatDate, formatTime } from "../helpers/global";
import useAuth from "../hooks/useAuth";
import SlidingSideBar from "./SlidingSideBar/SlidingSideBar";

const CalendarSidebar = ({
  appointment,
  onClose,
  title,
  onEdit,
  onDelete,
  onBook,
}) => {
  const { isLoggedIn } = useAuth();

  return (
    <SlidingSideBar
      visible={appointment}
      onClose={onClose}
      title={title}
      width="60%"
    >
      {appointment && (
        <div className="position-relative h-100 mid-text">
          <div className="d-flex justify-content-end w-100">
            {isLoggedIn ? (
              <>
                <Button
                  variant="primary-dark"
                  size="sm"
                  className="text-white me-2 small-text"
                  onClick={() => {
                    onEdit(appointment);
                    onClose();
                  }}
                >
                  <PencilSquare className="me-1" />
                  Edit
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    onDelete(appointment);
                    onClose();
                  }}
                  className="text-white small-text"
                >
                  <Trash className="me-1" />
                  Delete
                </Button>
              </>
            ) : (
              <Button
                size="sm"
                variant="primary-dark"
                className="text-white small-text "
                onClick={() => {
                  onBook(appointment);
                  onClose();
                }}
              >
                <CheckCircle className="me-1" />
                Book this appointment
              </Button>
            )}
          </div>

          <div className="my-3 border p-2 rounded">
            <strong className="large-text">General Information</strong>
            <hr className="my-2" />
            <div className="px-3">
              <Calendar className="me-1" /> {formatDate(appointment?.start)}
              <br />
              <Clock className="me-1" /> {formatTime(appointment?.start)} -{" "}
              {formatTime(appointment?.end)}
              <br />
              <CurrencyDollar className="me-1" /> {appointment?.slotPrice} USD
              <br />
              {appointment?.description && (
                <>
                  <FileEarmark className="me-1" /> {appointment?.description}
                </>
              )}
            </div>
          </div>
          {isLoggedIn &&
            appointment?.form &&
            Object.keys(appointment?.form).length > 0 && (
              <div className="my-3 border p-2 rounded">
                <strong className="large-text">Booking Information</strong>
                <hr className="my-2" />
                <div className="px-3">
                  {Object.keys(appointment?.form).map((key) => (
                    <div className="d-flex align-items-start mb-1">
                      <span className="fw-normal fw-bold">
                        {USER_DETAILS_KEY_VALUE[key]}
                      </span>
                      :
                      <span className="ms-1">
                        {isArray(appointment?.form[key])
                          ? appointment?.form[key].join(",")
                          : appointment?.form[key]}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
        </div>
      )}
    </SlidingSideBar>
  );
};

export default CalendarSidebar;
