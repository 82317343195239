import React from "react";

const VerticalBar = ({ width = 1, height, color, className = "" }) => {
  const barStyle = {
    width,
    height,
    backgroundColor: color,
  };

  return <div style={barStyle} className={className} />;
};

export default VerticalBar;
