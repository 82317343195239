import React from 'react'
import './bar.scss'
import { Button } from 'react-bootstrap'

const SlidingSideBar = ({
  children,
  visible = false,
  title = '',
  showCloseButton = true,
  onClose,
  width
}) => {
  const Header = () => {
    return (
      <>
        <div className='d-flex align-items-end'>
          <h5 className='flex-grow-1'>{title}</h5>
          {showCloseButton && (
            <Button variant='danger' onClick={onClose}>
              Close
            </Button>
          )}
        </div>
        <hr className='my-2' />
      </>
    )
  }

  return (
    <div
      id='bar-container'
      className={`${visible ? 'bar-visible' : 'bar-collapsed'}`}
      onClick={() => onClose()}
    >
      <div
        id='bar-content'
        onClick={(e) => e.stopPropagation()}
        style={{ width }}
      >
        {(title || showCloseButton) && <Header />}
        <div id='bar-body' style={{ height: 'calc(100% - 65px )' }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default SlidingSideBar
