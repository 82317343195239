import React from "react";
import { Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { RESET_PASSWORD_FIELDS } from "../../helpers/forms";
import useAuth from "../../hooks/useAuth";

const ResetPassword = ({
  resetPasswordFormMetadata,
  onResetPasswordFormMetadataChange,
  onHide,
  inProgress,
  onFormSubmit,
  forgotPassModalMetadata,
}) => {
  const { resettingPassword, updateForgotPasswordOTPvalue } = useAuth();
  return (
    <>
      <Modal
        show={resetPasswordFormMetadata}
        onHide={() => {
          onHide();
          updateForgotPasswordOTPvalue(false);
        }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton={!inProgress}>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {RESET_PASSWORD_FIELDS.map(({ key, label, type }) => {
            return (
              <Form.Group className="mb-3" key={key} id={`rp-form-${key}`}>
                <Form.Label className="input-label">{label}</Form.Label>
                <Form.Control
                  type={type}
                  placeholder={`Enter ${label}`}
                  value={resetPasswordFormMetadata?.[key]}
                  onChange={(e) => {
                    onResetPasswordFormMetadataChange(key, e.target.value);
                  }}
                  className="input-font"
                />
              </Form.Group>
            );
          })}
          <div className="w-100 d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={onFormSubmit}
              disabled={resettingPassword}
            >
              Submit
            </Button>
          </div>
          {resettingPassword && (
            <ProgressBar
              variant="dark"
              animated
              now={100}
              label="Resetting Password..."
              className="mt-2"
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResetPassword;
