import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

export default function StripeCheckoutForm({
  clientSecret,
  onPaymentSuccess,
  onStripePaymentClose,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (!stripe && elements) {
      return;
    }
  }, [stripe]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret.stripeClientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
      );
      if (error) {
        setMessage(error.message);
      } else {
        try {
          // complete payment
          elements.getElement(CardElement).unmount();
          toast.success("Payment Successfull");
          onStripePaymentClose();
          onPaymentSuccess && onPaymentSuccess("Success");
        } catch (e) {
          toast.error("Something went wrong!");
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      setMessage("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement id="card-element" onChange={() => setMessage(null)} />
      {message && (
        <div id="payment-message" className={`text-danger`}>
          {message}
        </div>
      )}
      {isLoading && (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      )}
      <Button
        size="sm"
        onClick={handleSubmit}
        className="px-2 mt-5 text-white w-100"
        variant="primary"
      >
        Pay Now
      </Button>
    </form>
  );
}
