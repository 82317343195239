import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../common/Header'

const ProtectedRouteLayout = () => {
  return (
    <div className="vh-100">
      <Header pageTitle="Availability" />
      <div style={{ height: 'calc( 100% - 90px  )' }}>
        <Outlet />
      </div>
    </div>
  )
}

export default ProtectedRouteLayout
