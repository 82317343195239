import React from 'react'
import { Navbar, Dropdown } from 'react-bootstrap'
import { PersonBadge } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import VerticalBar from './VerticalBar'
import useAuth from '../hooks/useAuth'

const Header = ({ showProfile = true, pageTitle = '' }) => {
  const { signOut } = useAuth()
  return (
    <Navbar expand="lg" className="px-2 sticky-top border-bottom bg-white">
      <div className="d-flex align-items-center mr-3">
        <Link to={'/'}>
          <img
            src="/SHC_Social-Profile_White-BG.jpg"
            alt="companyLogo"
            style={{
              width: '40px'
            }}
            className="me-2"
          />
          <img
            src="/SHC_Horizontal-Logo_Black_Transparent-BG.png"
            alt="companyLogo"
            style={{
              width: '140px'
            }}
            className="mt-2"
          />
        </Link>
        {pageTitle && (
          <>
            <VerticalBar height={20} className="mx-3 bg-muted" />
            <h6 className="mb-0 xlarge-text fw-bold">{pageTitle}</h6>
          </>
        )}
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        {showProfile && (
          <Dropdown drop="start">
            <Dropdown.Toggle
              size="sm"
              id="dropdown-basic"
              className="bg-white text-black border-dark "
            >
              <PersonBadge size={14} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={signOut}>Log out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header
