import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { CalendarDate, PlusLg } from "react-bootstrap-icons";
import AppointmentCalendar from "../../common/AppointmentCalendar";
import CalendarSidebar from "../../common/CalendarSidebar";
import {
  createAppointments,
  filterAppointmentsByTitle,
  toHTMLDateInputFormat,
  toHTMLTimeInputFormat,
} from "../../helpers/global";
import AddEditAppointment from "./AddEditAppointment";
import Sidebar from "./Sidebar";
import moment from "moment";
import FloatingButton from "../../common/FloatingButton";
import AlertModal from "../../common/AlertModal";
import { availabilityService } from "../../services/availabilityService";
import { toast } from "react-toastify";
import useAppChoices from "../../hooks/useAppChoices";

const Appointments = () => {
  const [activeAppointment, setActiveAppointment] = useState(null);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [activeFilter, setActiveFilter] = useState("all");
  const [appointments, setAppointments] = useState([]);
  const [quickSearchValue, setQuickSearchValue] = useState("");
  const [appointmentMetadata, setAppointmentMetadata] = useState(null);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [deleteAppointMent, setDeleteAppointment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const SLOT_PRICE = useAppChoices("SLOT_DEFAULT_PRICE")?.value ?? "";

  useEffect(() => {
    getAvailabilities();
  }, []);

  useEffect(() => {
    console.log({ appointmentMetadata });
  }, [appointmentMetadata]);

  const getAvailabilities = async () => {
    setIsLoading(true);
    try {
      const { response, error } = await availabilityService.search();
      if (error) {
        toast.error(error);
        return;
      }
      setAppointments(
        response?.timeSlots
          ? response?.timeSlots.map((event) => {
              return {
                ...event,
                start: new Date(event?.start),
                end: new Date(event?.end),
              };
            })
          : []
      );
    } catch (e) {
      toast.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onActiveAppointmentValueChange = (value) => {
    setActiveAppointment(value);
    setAppointmentDetails(value);
  };

  const onAppointmentAdd = async (appointment) => {
    const toBeEditAppointMent = { ...appointment };
    const isEditing = Boolean(toBeEditAppointMent?.editing);
    delete toBeEditAppointMent?.editing;
    delete toBeEditAppointMent?.createdBy;
    setAppointmentMetadata({ ...appointmentMetadata, loading: true });
    try {
      const { response, error } = isEditing
        ? await availabilityService.update(
            createAppointments(toBeEditAppointMent)[0]
          )
        : await availabilityService.create([
            ...createAppointments(appointment),
          ]);
      if (error) {
        toast.error(error);
        setAppointmentMetadata(null);
        return;
      }
      if (isEditing) {
        const editingAppointMentIndex = appointments.findIndex(
          (a) => a?.id === toBeEditAppointMent?.id
        );
        appointments[editingAppointMentIndex] = {
          ...response?.timeSlot,
          start: new Date(response?.timeSlot?.start),
          end: new Date(response?.timeSlot?.end),
        };
        setAppointments([...appointments]);
      } else {
        setAppointments((prevAppointments) => [
          ...response.timeSlots.map((e) => {
            return {
              ...e,
              start: new Date(e.start),
              end: new Date(e.end),
            };
          }),
          ...prevAppointments,
        ]);
      }
      toast.success(
        appointment?.editing ? "Successfully Updated!" : "Sucessfully Created!"
      );
      setAppointmentMetadata(null);
    } catch (e) {
      toast.error(e);
      setAppointmentMetadata(null);
    }
  };

  const onActiveFilterChange = (filterValue) => {
    setActiveFilter(filterValue);
  };

  const onQuickSearchValueChange = (e) => setQuickSearchValue(e);

  const onAppointmentModalChange = (value) => setAppointmentMetadata(value);

  const onAppointmentModalFieldsChange = (field, value) => {
    appointmentMetadata[field] = value;
    setAppointmentMetadata({ ...appointmentMetadata });
  };

  const handleOnSlotSelected = (slot) => {
    setAppointmentMetadata({
      date: moment(slot.start).format("yyyy-MM-DD"),
      start: moment(slot.start).format("hh:mm"),
      end: moment(slot.end).format("hh:mm"),
    });
  };

  const handleDeleteAppointment = async () => {
    const toBeDeleteAvailability = { ...deleteAppointMent };
    setDeleteAppointment({ ...deleteAppointMent, loading: true });
    try {
      const { error } = await availabilityService.delete(
        toBeDeleteAvailability
      );
      if (error) {
        toast.error(error);
        setDeleteAppointment(null);
        return;
      }
      setAppointments([
        ...appointments.filter((a) => a.id !== toBeDeleteAvailability.id),
      ]);
      setDeleteAppointment(null);
    } catch (e) {
      toast.error(e);
      setDeleteAppointment(null);
    }
  };

  useEffect(() => {
    let filterAppointments = filterAppointmentsByTitle(
      quickSearchValue,
      appointments
    );

    filterAppointments =
      activeFilter === "all"
        ? filterAppointments
        : filterAppointments.filter((a) => a.status === activeFilter);
    setFilteredAppointments([...filterAppointments]);
  }, [quickSearchValue, appointments, activeFilter]);

  if (isLoading) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Container fluid className="my-2 rounded h-100">
      <CalendarSidebar
        appointment={appointmentDetails}
        onEdit={
          (appointment) =>
            setAppointmentMetadata({
              ...appointment,
              editing: true,
              date: toHTMLDateInputFormat(appointment?.start),
              start: toHTMLTimeInputFormat(appointment?.start),
              end: toHTMLTimeInputFormat(appointment?.end),
            }) // add the date here so that add edit functionality would work as intended
        }
        onDelete={(appointment) => setDeleteAppointment(appointment)}
        onClose={() => setAppointmentDetails(null)}
        title={"Availability Detail"}
      ></CalendarSidebar>

      {appointmentMetadata && (
        <AddEditAppointment
          showProgress={appointmentMetadata?.loading}
          appointmentMetadata={appointmentMetadata}
          onAppointmentModalChange={onAppointmentModalChange}
          onAppointmentModalFieldsChange={onAppointmentModalFieldsChange}
          onAppointmentAdd={onAppointmentAdd}
        />
      )}
      <div className="d-flex h-100 mt-3 position-relative">
        <div className="px-2 pb-5">
          <Sidebar
            appointments={filteredAppointments}
            onActiveAppointmentValueChange={onActiveAppointmentValueChange}
            activeAppointment={activeAppointment}
            activeFilter={activeFilter}
            onActiveFilterChange={onActiveFilterChange}
            quickSearchValue={quickSearchValue}
            onQuickSearchValueChange={onQuickSearchValueChange}
          />
        </div>
        <div className="px-2 w-100 pb-2">
          <AppointmentCalendar
            onAppointmentClick={(appointment) =>
              setAppointmentDetails(appointment)
            }
            appointments={appointments}
            timeAndDateToScroll={activeAppointment?.start}
            onSlotSelect={handleOnSlotSelected}
            allowEditing={true}
            initialSmallScreenView={"day"}
            initialLargeScreenView={"month"}
          />
        </div>
      </div>
      <FloatingButton
        variant="dark"
        onClick={() => setAppointmentMetadata({ slotPrice: SLOT_PRICE })}
        LeftIcon={CalendarDate}
        RightIcon={PlusLg}
        text={"Add Availability"}
      />
      <AlertModal
        show={deleteAppointMent}
        onHide={() => setDeleteAppointment(null)}
        alertText="Are you sure you want to delete this appointment? This action cannot be undone!"
        onDismissClick={() => setDeleteAppointment(null)}
        onContinueClick={handleDeleteAppointment}
        showProgress={deleteAppointMent?.loading}
      />
    </Container>
  );
};

export default Appointments;
