export const APP_NAME = "Stafford Holistic Counseling";

export const ENDPOINTS = {
  //Users
  LOGIN: "login",
  REGISTER: "register",
  FORGOT_PASSWORD: "get-password-reset-link",
  RESET_PASSWORD: "reset-password",
  CHANGE_PASSWORD: "change-password",
  GENERATE_REGISTRATION_OTP: "get-registration-OTP",
  GET_ME: "get-me",
  UPDATE_USER_DETAILS: "update-user-details",
  //App Choices
  APP_CHOICES_BASE: "app-choices",
  CREATE_AVAILABILITY: "create-timeslots",
  SEARCH_AVAILABILITY: "list-timeslots",
  UPDATE_AVAILABILITY: "update-timeslot",
  DELETE_AVAILABILITY: "delete-timeslot",
  BOOK_AVAILABILITY: "apply-for-timeslot",
};

export const EVENT_STATUS = {
  Available: "primary",
  Booked: "success",
};

export const LOGIN_MODE = "login";
export const REGISTER_MODE = "register";

export const QUESTIONARIES = "Questionaries";
export const APPOINTMENT_CALENDER = "Appointment Calender";

export const APPOINTMENT_TABS = [
  { label: "All", key: "all" },
  { label: "Booked", key: "booked" },
  { label: "Available", key: "available" },
];

export const daysInWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const STRIPE_PK_KEY = "pk_live_Gwt8XrRObPKAJePseuFY2OC000lJ6E2erd";
//"pk_test_51MjyMSHrEcstegtGC08Qp51CJXQyLPQzCQDA7btAGVCD8lvk0esioBgbabGsGpMsnLDMp9PgKL3yTFRbQpx9Nv0N00dxq16nJq";

export const USER_DETAILS_KEY_VALUE = {
  firstName: "First Name",
  lastName: "Last Name",
  phone: "Phone Number",
  email: "Email",
  pastHospitalization: "Hospitilized in past",
  goals: "Goals",
  beenToTherapy: "Have been to therapy",
  receivedMentalHealthDiagnosis: "Received mental health diagnosis",
  hospitalizedForMentalHealth: "Hospitilized for mental health",
  topTwoConcerns: "Any concern",
  questions: "Questions",
  servicesInterestIn: "Services interested in",
  hearedAbout: "From where have they heard About us.",
};
